import React from "react";
import Layout from "components/layout";
import { graphql } from 'gatsby'

import Details from "components/Page/Guides/Details";



const Guide = ({data}) => {
  const guide = data.contentfulGuide
  
  return ( 
    <Layout >
      <Details data={guide} />
    </Layout >
  )
}

export default Guide


// language=GraphQL
export const pageQuery = graphql`
  query GuideQuery($id: String!) {
    contentfulGuide(id: { eq: $id }) {
      handle
      name
      subheadline
      coverPhoto {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      videos {
        file {
          url
        }
      }
    }
  }
`;