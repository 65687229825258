import React from "react";

// Styles
import styled from 'styled-components';
import { h2, body } from 'components/Theme/Copy';
import { GatsbyImage } from "gatsby-plugin-image";
import { media } from 'components/Theme/Styles';

const Container = styled.div`
  width:calc(100% - 1.5rem);
  margin-right:1.5rem;
  margin-bottom:3rem;
  display:flex;
  flex-direction:row;
  justify-content:space-evenly;
`;

const Headline = styled(h2)`
  margin-top:1.5rem;
`;

const HeadlineContainer = styled.div`
  display:flex;
  flex-direction:column;
  width:50%;
`;

const Image = styled(GatsbyImage)`
  border-radius:0.5rem;
  width:20rem;
  height:100%;
  ${media.lessThan("tablet")`
    width:100% !important;
  `};
`;

const Subheadline = styled(body)`
  margin-top:1.5rem;
  font-size:1.6rem;
  line-height:2.2rem;
`;

const Details = ({data}) => {
  
  return ( 
    <Container>
      <Image image={data?.coverPhoto?.gatsbyImageData} />

      <HeadlineContainer>
        <Headline>{data.name}</Headline>
        <Subheadline>{data.subheadline}</Subheadline>
      </HeadlineContainer>
    </Container>
  )
}

export default Details
